<template>
    <div v-if="event">
        <header>
            <breadcrumbs
                v-if="primaryPerformer"
                :event-id="eventId"
                :locationId="locationId"
                :performer-id="primaryPerformer.id"
                @breadcrumb-selected="onBreadcrumbSelected" />
            <h1 class="header__primary-heading">{{ event.name }}</h1>
            <h2 class="header__secondary-heading">
                <span>{{ $formatDate(event.local_date, 'dddd, MMMM D, YYYY') }}</span>&#183;
                <span v-if="event.tbd">{{ $t('event.time-tbd') }}</span>
                <span v-else>{{ $formatDate(event.local_date, 'h:mma') }}</span>&nbsp;&#183;
                <span>{{ event.venue.name }}, {{ event.venue.city_name }} {{ event.venue.subdivision }}</span>
            </h2>
        </header>
        <fx-seat-select
            enable-panoramas
            include-fees
            v-if="event"
            :enable-pinning="enablePinning"
            :event="event"
            :filter-quantity="filterQuantity"
            :is-mobile="$store.state.isMobile"
            :isMobileFiltersOpen="isMobileFiltersOpen"
            :purchaseButtonCopy="'Checkout'"
            :quantity="selectedQuantity"
            :quantityFilterCopy="'Ticket quantity'"
            :ticket="ticketId"
            :ticketListHeaderCopy="'Select your tickets.'"
            :disclaimer-content-line-height="18"
            @close-mobile-filters="onCloseMobileFilters"
            @electronic-only-toggle-applied="onElectronicOnlyToggleApplied"
            @manual-zoom-interaction="onManualZoomInteraction"
            @map-reset="onMapReset"
            @map-row-selected="onMapRowSelected"
            @map-section-selected="onMapSectionSelected"
            @open-mobile-filters="onOpenMobileFilters"
            @pin-toggled="onPinToggled"
            @price-filter-changed="onPriceFilterApplied"
            @purchase="onTicketPurchase"
            @quantity-filter-applied="onQuantityFilterApplied"
            @quantity-filters-selected="onQuantityFilterSelected"
            @quantity-selected="onQuantitySelected"
            @seatview-closed="onSeatViewClosed"
            @seatview-expanded="onSeatViewExpanded"
            @ticket-deselected="onTicketDeselected"
            @ticket-listing-clicked="onTicketListingClicked"
            @ticket-selected="onTicketSelected"
            @zoom-button-clicked="onZoomButtonClicked"
        >
            <template slot="precheckout-ticket-info" slot-scope="props">
                <ul class="precheckout-ticket-info">
                    <li v-if="props.selectedTicket.notes">
                        <TicketInfoSellerNotes :seller-notes="props.selectedTicket.notes" />
                    </li>
                    <li class="precheckout-ticket-info__sales-pitch">
                        <span class="icon-ticket-2 sales-pitch__icon sales-pitch__icon--icon-ticket-2"></span>
                        <span v-if="event.is_lmlp && props.selectedTicket.stock_type.key === 'hard'" v-html="$t('delivery-details.hard-lmlp')"></span>
                        <span v-else v-html="$t('delivery-details.' + props.selectedTicket.stock_type.key)"></span>
                    </li>
                    <li>
                        <TicketInfoGuarantee :guarantee="$t('event.vivid-seats-guarantee')" />
                    </li>
                </ul>
            </template>
        </fx-seat-select>
    </div>
</template>

<script>
import { TICKET_LISTING } from 'core/constants/googleAnalyticEvents'
import BreadcrumbComponent from '../../components/BreadcrumbComponent.vue'
import DatePlugin from 'core/plugins/DatePlugin.js'
import DateUtil from 'core/utilities/DateUtil.js'
import SeatSelectComponent from 'core/components/SeatSelectComponent.vue'
import TicketInfoGuarantee from 'core/components/TicketInfoGuaranteeComponent.vue'
import TicketInfoSellerNotes from 'core/components/TicketInfoSellerNotesComponent.vue'

export default {
    head: {
        title() {
            let title = this.$t('event.title-event-default')
            if (this.event) {
                if (this.event.taxonomy.segment === "sports") {
                    title = this.$t('event.title-sports', {
                        title: this.event.name,
                        date: DateUtil.format(this.event.local_date, 'MMM D, YYYY')
                    })
                }
                else {
                    title = this.$t('event.title-non-sports', {
                        title: this.event.name,
                        venue: this.event.venue.name,
                        date: DatePlugin.format(this.event.local_date, 'MMM D, YYYY')
                    })
                }
                this.$googleTagManager.pushPageView(this.$route.fullPath, title)
            }
            return {
                inner: title
            }
        }
    },
    beforeCreate() {
        this.locationId = this.$route.query.location_id || null
    },
    props: ['eventId'],
    data() {
        return {
            enablePinning: false,
            event: null,
            filterQuantity: null,
            isMobileFiltersOpen: false,
            primaryPerformer: null,
            selectedQuantity: null,
            ticketId: null,
        }
    },
    created() {
        this.$marketplace
            .getEvent(this.eventId)
            .then(event => {
                this.event = event
                this.primaryPerformer = (this.event.performers || []).find(performer => performer.is_primary) || null
                this.enablePinning = (this.event.taxonomy || {}).segment === 'sports'
                this.$emit('updateHead')
            }, errors => {
                this.$router.replace({ name: 'not-found' })
            })
    },
    methods: {
        onBreadcrumbSelected() {
            this.$googleTagManager.pushEvent('event', {
                eventCategory: 'Event Listings Page',
                eventAction: 'click',
                eventLabel: 'Header Navigation - clicked'
            })
        },
        onCloseMobileFilters() {
            this.$router.replace({
                query: this.updateQuery({
                    mobile_filters: 0
                })
            })
        },
        onElectronicOnlyToggleApplied() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.ELECTRONIC_ONLY_FILTER_APPLIED)
        },
        onManualZoomInteraction() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.GESTURE_ZOOM)
        },
        onMapSectionSelected() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.MAP_SECTION_SELECTED)
        },
        onMapReset() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.MAP_RESET)
        },
        onMapRowSelected() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.MAP_ROW_SELECTED)
        },
        onOpenMobileFilters() {
            this.$router.push({
                query: this.updateQuery({
                    mobile_filters: 1
                })
            })
        },
        onPinToggled(pin) {
            if (!pin.isActivated) { return }
            this.$googleTagManager.pushEvent('event', pin.isPreCheckout ? TICKET_LISTING.PRE_CHECKOUT_LISTING_PINNED : TICKET_LISTING.TICKET_LISTING_PINNED)
        },
        onPriceFilterApplied(change) {
            if (change.inputType === 'slider' && change.boundary === 'min') {
                this.$googleTagManager.pushEvent('event', TICKET_LISTING.MIN_PRICE_FILTER_SLIDER_NODE_APPLIED)
            }
            else if (change.inputType === 'slider' && change.boundary === 'max') {
                this.$googleTagManager.pushEvent('event', TICKET_LISTING.MAX_PRICE_FILTER_SLIDER_NODE_APPLIED)
            }
            else if (change.inputType === 'input' && change.boundary === 'min') {
                this.$googleTagManager.pushEvent('event', TICKET_LISTING.MIN_PRICE_FILTER_TEXT_INPUTTED)
            }
            else if (change.inputType === 'input' && change.boundary === 'max') {
                this.$googleTagManager.pushEvent('event', TICKET_LISTING.MAX_PRICE_FILTER_TEXT_INPUTTED)
            }
        },
        onQuantityFilterSelected(filterQuantity) {
            this.$router.push({
                query: this.updateQuery({
                    filter_quantity: filterQuantity
                })
            })
        },
        onQuantitySelected(quantity) {
            this.$router.replace({
                query: this.updateQuery({
                    quantity: quantity
                })
            })
        },
        onSeatViewClosed() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.SEAT_VIEW_COLLAPSED)
        },
        onSeatViewExpanded() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.SEAT_VIEW_EXPANDED)
        },
        onTicketDeselected() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.PRE_CHECKOUT_BACK_TO_TICKET_LISTINGS)
            this.$router.replace({
                query: this.updateQuery({
                    ticket_id: null,
                    quantity: null
                })
            })
        },
        onTicketListingClicked() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.NON_STRIKETHROUGH_TICKET_LISTING_CLICKED)
        },
        onTicketPurchase(ticket) {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.PURCHASE)
            this.$store.dispatch('postMessage', {
                event: 'ticket-selected',
                data: {
                    event: this.event,
                    ticket
                }
            })
        },
        onTicketSelected(ticketId) {
           this.$router.push({
                query: this.updateQuery({
                    ticket_id: ticketId,
                })
            })
        },
        onQuantityFilterApplied() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.QUANTITY_FILTER_APPLIED)
        },
        onZoomButtonClicked() {
            this.$googleTagManager.pushEvent('event', TICKET_LISTING.ZOOM_BUTTON_CLICKED)
        },
        updateQuery(queryParams) {
            let query = Object.assign({}, this.$route.query, queryParams)
            Object.keys(query).forEach(param => {
                if (!query[param]) {
                    delete query[param]
                }
            })
            return query
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (this.ticketId === Number(route.query.ticket_id)) {
                    this.$googleTagManager.pushEvent('event', TICKET_LISTING.PURCHASE_QUANTITY_SELECTED)
                }
                this.ticketId = Number(route.query.ticket_id) || null
                this.selectedQuantity = Number(route.query.quantity) || null
                this.isMobileFiltersOpen = Boolean(Number(route.query.mobile_filters))
                this.filterQuantity = Number(route.query.filter_quantity) || 0
            }
        }
    },
    components: {
        'breadcrumbs': BreadcrumbComponent,
        'fx-seat-select': SeatSelectComponent,
        TicketInfoGuarantee,
        TicketInfoSellerNotes
    }
}
</script>

<style lang="postcss" scoped>
@import "theme";

header {
    height: 90px;
    @media (--mq-medium-max) {
        height: 85px;
    }
}

.header__primary-heading {
    color: var(--c-gray-5);
    font-size: var(--fs-largest);
    font-weight: var(--fw-bold);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.header__secondary-heading {
    margin: 5px 0 0 0;
    font-size: 16px;
    color: var(--c-gray-5);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

>>> .fx-seat-select {
    height: calc(100vh - 90px) !important;
    & .tu-map-component {
        max-width: calc(100vh - 90px) !important;
    }
    @media (--mq-medium-max) {
        height: calc(100vh - 85px) !important;
    }
}

>>> .fx-ticket-header--seatview.panoramic {
    @media (--mq-large-min) {
        height: calc(55vh - 90px) !important;
    }
}

>>> .bottom .kind--icon.variant--knockout {
    &:active, &.active  {
        color: var(--c-secondary) !important;
    }
    & :active {
        color: var(--c-secondary) !important;
    }
}

>>> .fx-ticket-pin {
    color: var(--c-gray-7) !important;
    &:hover, &:focus {
        border: none !important;
        background-color: var(--c-gray-9) !important;
    }
    &.active {
        color: var(--c-secondary) !important;
    }
}

>>> .vue-slider-process {
    background-color: var(--c-primary) !important;
}

>>> .fx-pinned-ticket {
    border-color: var(--c-primary) !important;
}

>>> .kind--default.variant--accent {
    background-color: var(--c-primary) !important;
    &:active {
        background: var(--c-primary-dk) !important;
    }
    &:focus {
        box-shadow: 0 0 21px 0 var(--c-primary-dk) !important;
    }
    &:hover {
        background: linear-gradient(180deg, var(--c-primary-dk), var(--c-primary)) !important;
    }
}

>>> .fx-switch>.switch.active>.rail {
    background-color: var(--c-primary) !important;
}

>>> .kind--link {
    &:active, &:focus {
        color: var(--c-primary-dk) !important;
        border-color: var(--c-primary-dk) !important;
    }
}

>>> .kind--icon.variant--square:focus {
    box-shadow: 0 0 21px 0 var(--c-primary) !important;
}

>>> .kind--icon.variant--default:focus {
    box-shadow: 0 0 21px 0 var(--c-primary) !important;
}

>>> .ticket-price {
    color: var(--c-gray-5) !important;
}

>>> .ticket-section {
    color: var(--c-gray-5) !important;
}

>>> .fx-pinned-tickets-button {
    background-color: var(--c-primary) !important;
}

>>> .fx-ticket-details__checkout-options {
    & .fx-selection, & .fx-checkout-total, & .icon-caret-down {
        color: #444649 !important;
    }
}

>>> .fx-ticket-details__seating-info {
    & .secondary {
        margin-top: 4px;
        & .price {
            & .primary {
                margin-top: 0;
            }
            & .secondary {
                float: right;
                margin-top: 2px;
            }
        }
    }
}

>>> .left {
    & h3 {
        font-weight: var(--fw-medium) !important;
    }
}

>>> .fx-ticket-quantity-filter-row {
    font-weight: var(--fw-medium) !important;
}

>>> .precheckout-ticket-info {
    & li {
        margin-bottom: 14px !important;
    }
    & .precheckout-ticket-info__sales-pitch {
        display: flex;
        & .sales-pitch__icon {
            color: var(--c-gray-4);
            display: inline-flex;
            flex-shrink: 0;
            font-size: 18px;
            margin-right: 8px;

            & .sales-pitch__icon--icon-ticket-2 {
                margin-top: 3px
            }
        }
    }
}

>>> .seller-note-details {
    font-size: 14px !important;
}

>>> .zone-seating-description {
    font-size: 14px !important;
}

>>> .no-seat-view .back {
    color: var(--c-primary) !important;
}


>>> .fx-ticket-list > li {
    &:hover, &:focus, &.highlighted {
        border-left-color: var(--c-primary) !important;
    }
}

>>> .icon-filters {
    font-size: 13px !important;
}

>>> .disclaimer-show-more-icon-down, >>>.disclaimer-show-more-icon-up {
    top: -1px !important;
}

>>> .disclaimer__content {
    font-size: 14px;
}
</style>
