import EventPage from './views/pages/EventPage.vue'
import NotFoundPage from './views/pages/NotFoundPage.vue'
import PerformerPage from './views/pages/PerformerPage.vue'

export default [
    {
        name: 'performer',
        path: '/performers/:performerId',
        component: PerformerPage,
        props: true
    },
    {
        name: 'event',
        path: '/events/:eventId',
        component: EventPage,
        props: true
    },
    {
        name: 'not-found',
        path: '/not-found',
        component: NotFoundPage
    },
    {
        path: '*',
        component: NotFoundPage
    }
]
