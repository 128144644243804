import Vue from 'vue'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import MobileDetect from 'mobile-detect'
import App from './App.vue'
import MarketplacePlugin from 'core/plugins/MarketplacePlugin.js'
import DatePlugin from 'core/plugins/DatePlugin.js'
import NumeralPlugin from 'core/plugins/NumeralPlugin.js'
import StockTypePlugin from 'core/plugins/StockTypePlugin.js'
import DOMUtil from 'core/utilities/DOMUtil.js'
import GoogleTagManagerPlugin from 'core/external-plugins/GoogleTagManagerPlugin.js'
import FullStoryPlugin from 'core/external-plugins/FullStoryPlugin'
import env from  'env'
import copy from './copy.json'
import 'core-js/stable'
import queryString from 'query-string'
import 'regenerator-runtime/runtime'
import routes from './routes.js'


// Initialize plugins
Vue.use(VueHead, {
    separator: '',
    complement: ''
})

Vue.use(DatePlugin)
Vue.use(NumeralPlugin)
Vue.use(StockTypePlugin)

if (env.IS_FULLSTORY_ENABLED) {
    Vue.use(FullStoryPlugin, {
        isIframe: true
    })
}

const query = queryString.parse(location.search)

const affilateTrackingIds = {
    '2982': env.GA_PLACEPASS_ECOM,
    '2985': env.GA_MARRIOT_REWARDS,
    '2986': env.GA_HERTZ,
    '2987': env.GA_DOLLAR_CAR_RENTAL,
    '2988': env.GA_UNITED,
    '2989': env.GA_SOUTHWEST,
    '2990': env.GA_SVC,
    '2991': env.GA_WVC,
    '2992': env.GA_MVC,
    '2993': env.GA_MVCP,
    '2994': env.GA_VIST,
    '3011': env.GA_INTER,
    '3012': env.GA_AQUA
}

if (query.affiliate_id && affilateTrackingIds[query.affiliate_id]) {
    Vue.use(GoogleTagManagerPlugin, {
        containerIds: [env.GTM_SUPER_ACCOUNT, env.GTM_PLACEPASS_PARTNERS],
        partnerGA: affilateTrackingIds[query.affiliate_id]
    })
}
else {
    Vue.use(GoogleTagManagerPlugin, { containerId: env.GTM_SUPER_ACCOUNT })
}

// Initialize HTTP client
Vue.use(VueResource)

Vue.http.headers.common['X-AFFILIATE-ID'] = '2982'
Vue.http.headers.common['X-CHANNEL'] = 'Web'
Vue.use(MarketplacePlugin, {
    basePath: '/marketplace-api'
})

// Initialize global state
Vue.use(Vuex)
const store = new Vuex.Store({
    strict: true,
    state: {
        cdnUrl: env.CDN_URL,
        currency: 'USD',
        isMobile: Boolean(new MobileDetect(window.navigator.userAgent).mobile())
    },
    actions: {
        postMessage(context, { eventType, data }) {
            window.parent.postMessage(JSON.stringify({
                dataVersion: 'v2.0',
                eventType,
                data
            }), '*')
        }
    }
})

// Initialize i18n plugin
Vue.use(vuexI18n.plugin, store)
// Initialize available languages
for (let language in copy) {
    Vue.i18n.add(language, copy[language])
}
Vue.i18n.set('en')

// Initialize application routes
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // savedPosition is only available when user navigates with back/forward
            return savedPosition
        } else if (to.name === from.name) {
            // Use current scroll position when url changes but the route is the same
            // example: query param change
            return
        } else {
            // Reset scroll position
            return { x: 0, y: 0 }
        }
    }
})

router.afterEach((to, from) => {
    store.dispatch('postMessage', {
        eventType: 'routeChanged',
        data: {
            from: from.name,
            to: to.name
        }
    })
})

// Initialize app resize listener
DOMUtil.addElementResizeListener(document.getElementById('appContainer'), element => {
    let updatedHeight = element.offsetHeight
    if (store.state.isMobile && router.currentRoute.name === 'event') {
        updatedHeight = window.screen.availHeight
    }
    store.dispatch('postMessage', {
        eventType: 'contentResized',
        data: {
            updatedHeight
        }
    })
})

// Bootstrap application
window.fxApp = new Vue({
    el: '#app',
    render: h => h(App),
    store,
    router
})
