<template>
    <ul class="breadcrumbs">
        <li class="breadcrumbs__item">
            <fx-button
                class="breadcrumbs__link"
                :class="{ 'breadcrumbs__link--active' : performerRoute }"
                kind="link"
                @click="redirect(performerRoute)">
                1. Select A Date
            </fx-button>
        </li>
        <li class="breadcrumbs__item">
            <fx-button
                class="breadcrumbs__link"
                :class="{ 'breadcrumbs__link--active' : eventRoute }"
                kind="link">
                2. Select Your Seats
            </fx-button>
        </li>
        <li class="breadcrumbs__item">
            <fx-button
                class="breadcrumbs__link"
                kind="link">
                3. Review and Book
            </fx-button>
        </li>
    </ul>
</template>

<script>
import ButtonComponent from 'core/components/ButtonComponent.vue'

export default {
    components: {
        'fx-button': ButtonComponent
    },

    created() {
        let query = {}

        if (this.locationId) {
            query.location_id = this.locationId
        }

        this.eventRoute = null

        if (this.eventId) {
            this.eventRoute = {
                name: 'event',
                params: {
                    eventId: this.eventId
                },
                query
            }
        }

        this.performerRoute = {
            name: 'performer',
            params: {
                performerId: this.performerId
            },
            query
        }
    },
    props: {
        eventId: {
            default: null,
            required: false,
            type: [Number, String]
        },
        locationId: {
            default: null,
            required: false,
            type: [Number, String]
        },
        performerId: {
            required: true,
            type: [Number, String]
        }
    },
    methods: {
        redirect(route) {
            this.$router.push(route)
            this.$emit('breadcrumb-selected')
        }
    }
}
</script>

<style lang="postcss" scoped>
@import 'theme';

.breadcrumbs {
    color: var(--c-gray-1);
    display: flex;
    flex-direction: row;
    line-height: 1;
    list-style: none;
    margin-top: 0;
    margin-bottom: 16px;
    padding: 0;
    @media (--mq-medium-max) {
        margin-bottom: 5px;
    }

    & .breadcrumbs__item {
        border-left: 1px solid var(--c-gray-1);
        margin-right: 10px;
        padding-left: 10px;

        & * {
            cursor: not-allowed;
        }

        &:first-child {
            border-left: none;
            padding-left: 0;
        }

        & .kind--link:hover, & .kind--link:focus {
            border-color: transparent !important;
        }
    }

    & .breadcrumbs__link {
        color: var(--c-gray-1);
    }

    & .breadcrumbs__link--active {
        color: var(--c-gray-5);
        cursor: pointer;
        line-height: 12px;

        & >>> .fx-button-label {
            color: var(--c-gray-5);
        }
    }
}

>>> .fx-button-label {
    color: var(--c-gray-1);
    font-family: var(--ff-primary);
    font-size: 16px;
    line-height: 12px;

    @media (--mq-medium-max) {
        font-size: 12px;
    }
}
</style>