<template>
    <div class="app-component">
        <main>
            <modal
                size="large"
                :open="isModalOpen"
                @close="closeModal"
                :heading="'Information Regarding the Coronavirus Impact'"            
            >
                <br>
                <div>
                    Vivid Seats is closely monitoring the current global situation involving the coronavirus (COVID-19), especially as it relates to the impact on live events. We want to make sure customers have the information they need regarding their ticket purchase from Vivid Seats.
                </div>
                <br>
                <div>
                    If a customer purchased tickets to an event that is <strong>canceled</strong> with no rescheduled date, they are entitled to a full refund of the purchase price, including delivery charges, per Vivid Seats' 100% Buyer Guarantee. There is no need to take any action, and our award-winning customer service team will contact customers directly regarding the next steps.
                </div>
                <br>
                <div>
                    If a customer's event has been <strong>postponed</strong>, the purchase will likely be valid for the new date, though every event may be different. There is no need to take any action, and our customer service team will contact customers directly once we have an update from event organizers. Vivid Seats has an outstanding track record of providing excellent customer service, and we are committed to helping our customers during this time.
                </div>
                <br>
                <div>
                    As always, we encourage customers to reach out to us if they have any questions, as we are dedicated to making sure every customer is satisfied.
                </div>
                <br>
                <h2 class="buyer-faq">Buyer FAQs</h2>
                <h4 class="warning-subheader">I purchased tickets to an event that has been canceled due to the coronavirus (COVID-19). What happens now?</h4>
                <ul>
                    <li class="warning-item">
                        If your event has been canceled, your purchase is entitled to a full refund of the purchase price, including delivery charges, per our 100% Buyer Guarantee. There is no action you need to take, and our customer service team will email you with details on a full refund.
                    </li>
                </ul>
                <h4 class="warning-subheader">When will I receive my full refund?</h4>
                <ul>
                    <li class="warning-item">If your event was canceled and you opted for a full refund, you should expect to receive your refund within approximately 2-4 weeks.</li>
                    <li class="warning-item">Refund timing may vary due to the large number of events being canceled.</li>
                </ul>
                <h4 class="warning-subheader">How do I find out whether or not my show is canceled or postponed due to the coronavirus (COVID-19)?</h4>
                <ul>
                    <li class="warning-item">Events may be canceled by the team, venue, performer, or promoter. Ticket marketplaces like Vivid Seats do not control these decisions. Therefore, we recommend checking the venue and artist websites, event pages, and social media channels. Vivid Seats will email customers whose events are canceled or postponed.</li>
                </ul>
                <h4 class="warning-subheader">Do you still have questions?</h4>
                <ul>
                    <li class="warning-item">Email our customer service team at <span class="customer-service-email">care@vividseats.com</span>, and we'll get back to you!</li>
                </ul>
            </modal>
            <router-view></router-view>
        </main>
    </div>
</template>

<script>

import modal from 'core/components/ModalComponent.vue'

export default {
    components: {
        modal
    },
    data () {
        return {
            isModalOpen: false
        }
    },
    methods: {
        closeModal () {
            this.isModalOpen = false
        },
        openModal () {
            if (!this.isModalOpen) {
                this.isModalOpen = true
            }
        }
    }
}
</script>

<style lang="postcss">
@import "./styles/main.css";

>>> .modal {
    opacity: 1;
}

.buyer-faq {
    font-weight: 400;
    margin-bottom: 16px;
}

.customer-service-email {
    color: #1c74a5;
}

.warning-banner {
    background: #FCF1B6;
    color: #444649;
    padding: 8px;
    text-align: center;
    width: 100%;
    @media (--mq-medium-min) {
        margin-bottom: 18px;
    }
    & a {
        color: #444649;
        text-decoration: underline;
        cursor: pointer;
    }
}

.warning-item {
    margin-bottom: 8px;
}

.warning-subheader {
    margin-bottom: 8px;
}

</style>