<template>
    <div class="performer-component">
        <header>
            <breadcrumbs
                :location-id="locationId"
                :performer-id="performerId" />
            <h1 class="event-title" v-if="performer">{{ performer.name }}</h1>
            <div class="fx-hidden--large fx-hidden--full">
                <fx-date-range
                    v-model="dateRange"
                    min-date="today"
                    @input="onDateRangeChange()"></fx-date-range>
            </div>
        </header>
        <div class="performer-content">
            <fx-event-list
                :events="events"
                :loading-events="loadingEvents"
                :loading-more-events="loadingMoreEvents"
                :pagination="pagination"
                @event-selected="onEventSelected"
                @load-more="onLoadMoreEvents"
                class="left">
                <template slot="event-price-desktop" slot-scope="props">
                    <div class="event-price">
                        <span class="icon icon-chevron-lg-right starting-price__icon"></span>
                    </div>
                </template>
                <span slot="event-price-mobile" slot-scope="props">
                </span>
                <template slot="events-not-found">
                    <div class="event-list-error">
                        <div>
                            <h1 class="error-message">{{ $t('performer.no-events-message') }}</h1>
                            <p class="error-suggestion">{{ $t('performer.no-events-suggestion') }}</p>
                        </div>
                    </div>
                </template>
            </fx-event-list>
            <div class="right fx-hidden--small fx-hidden--medium">
                <section class="date-selection">
                    <header class="date-selection__header">
                        <h4 class="date-selection__heading">{{ $t('performer.date-filter-heading') }}</h4>
                        <fx-button
                            @click="resetDateFilter"
                            kind="link"
                            class="date-selection__clear-dates"
                            v-show="dateRange">
                            {{ $t('performer.reset-date-filter-action') }}
                        </fx-button>
                    </header>
                    <div class="date-selection__date-filter">
                        <fx-date
                            @input="onDateRangeChange()"
                            min-date="today"
                            mode="range"
                            v-model="dateRange">
                        </fx-date>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { PERFORMER_LANDING_PAGE } from 'core/constants/googleAnalyticEvents'
import BreadcrumbComponent from '../../components/BreadcrumbComponent.vue'
import ButtonComponent from 'core/components/ButtonComponent.vue'
import DateComponent from 'core/components/DateComponent.vue'
import DateRangeComponent from 'core/components/DateRangeComponent.vue'
import DateUtil from 'core/utilities/DateUtil.js'
import EventListComponent from 'core/components/EventListComponent.vue'
import Utils from 'core/utilities/Utils.js'

const ACTION = {
    FILTER: 'Filter',
    LISTING: 'Listing'
}

export default {
    components: {
        'breadcrumbs': BreadcrumbComponent,
        'fx-button': ButtonComponent,
        'fx-date-range': DateRangeComponent,
        'fx-date': DateComponent,
        'fx-event-list': EventListComponent
    },
    created() {
        const query = this.$route.query
        if (query.date_from && query.date_to) {
            this.dateRange = [
                DateUtil.parse(query.date_from),
                DateUtil.parse(query.date_to)
            ]
        }
        this.locationId = this.$route.query.location_id || null
        this.$marketplace
            .getPerformer(this.performerId)
            .then(performer => {
                this.performer = performer
                this.hasPerformerHomeVenue = Boolean(this.performer.home_venue)
                this.$emit('updateHead')
                this.updateEvents()
            })
            .catch(() => {
                this.$router.replace({ name: 'not-found' })
            })
    },
    data() {
        return {
            dateRange: null,
            events: [],
            hasPerformerHomeVenue: false,
            loadingEvents: true,
            loadingMoreEvents: false,
            locationId: null,
            pagination: {},
            performer: null
        }
    },
    head: {
        title() {
            let title = this.$t('event.title-performer-default')
            if (this.performer) {
                title = this.$t('event.title-with-performer', {
                    performer: this.performer.name
                })
                this.$googleTagManager.pushPageView(this.$route.fullPath, title)
            }
            return {
                inner: title
            }
        }
    },
    methods: {
        onDateRangeChange() {
            if (this.dateRange && this.dateRange.length === 2) {
                this.$googleTagManager.pushEvent('event', PERFORMER_LANDING_PAGE.DATE_RANGE_FILTER_APPLIED)
            }
            this.updateEvents(1)
        },
        onEventSelected(event) {
            this.$googleTagManager.pushEvent('event', PERFORMER_LANDING_PAGE.EVENT_SELECTED)
            const query = {}

            if (this.locationId) {
                query.location_id = this.locationId
            }

            this.$router.push({
                name: 'event',
                params: {
                    eventId: event.id
                },
                query
            })
        },
        onLoadMoreEvents() {
            this.$googleTagManager.pushEvent('event', PERFORMER_LANDING_PAGE.LOAD_MORE_EVENTS_SELECTED)
            if (this.pagination.current_page < this.pagination.last_page) {
                this.updateEvents(this.pagination.current_page + 1)
            }
        },
        resetDateFilter() {
            this.dateRange = null
            this.updateEvents()
        },
        updateEvents(page = 1) {
            let dateFrom = null
            let dateTo = null
            if (this.dateRange && this.dateRange.length === 2) {
                const dateFormat = 'YYYY-MM-DD'
                dateFrom = this.$formatDate(this.dateRange[0], dateFormat)
                dateTo = this.$formatDate(this.dateRange[1], dateFormat)
            }
            this.loadingEvents = page === 1
            this.loadingMoreEvents = !this.loadingEvents
            return this.$marketplace.getEntityEvents('performers', this.performerId, {
                all_in_price: 1,
                date_end: dateTo,
                date_start: dateFrom,
                location_id: this.hasPerformerHomeVenue ? null : this.locationId,
                opponent_id: null,
                page,
                utc_offset: this.$formatDate(Date.now(), 'Z'),
                whereabouts: this.hasPerformerHomeVenue ? 'home' : null
            })
            .then(response => {
                const events = response.chronological.results
                this.events = this.loadingMoreEvents ? this.events.concat(events) : events
                this.pagination = response.chronological.meta
            })
            .finally(response => {
                this.loadingEvents = false
                this.loadingMoreEvents = false

                let params = {
                    date_from: dateFrom,
                    date_to: dateTo
                }
                let from  = this.$route.fullPath
                let to = this.$router.resolve({query: params}).route.fullPath

                if (from !== to) {
                    // Update route
                    this.$router.replace({
                        name: this.$route.name,
                        query: Utils.updateQueryParams(this.$route.query, params)
                    })
                }

                this.$nextTick(() => {
                    this.$store.dispatch('postMessage', {
                        eventType: 'contentLoaded',
                        data: {
                            finalHeight: document.getElementById('appContainer').offsetHeight
                        }
                    })
                })
            })
        }
    },
    props: ['performerId']
}
</script>

<style lang="postcss" scoped>
@import "theme";

.performer-component {
    @media (--mq-medium-max) {
        flex-direction: column;
    }
}

.performer-content {
    display: flex;
    justify-content: space-around;
}

.event-title {
    color: var(--c-gray-5);
    margin: 0 0 30px 0;
}

.date-selection {
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border: 1px solid var(--c-gray-8);
}

.date-selection__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    & >>> .fx-button {
        margin-right: 30px;
    }
}

.date-selection__heading {
    font-size: var(--fs-large);
    margin-left: 30px;
}

.date-selection__date-filter {
    margin: 0 30px 30px 30px;
}

.event-list-error {
    align-items: center;
    background-color: white;
    color: var(--c-gray-5);
    display: flex;
    justify-content: center;
    padding: 12px;
    text-align: center;
}

.left {
    flex: 1 1 auto;
}

.right {
    padding-left: 30px;
}

.fx-event-price {
    color: var(--c-gray-5);
}

.event-price--mobile {
    color: var(--c-primary)
}

.starting-price {
    align-items: center;
    display: flex;
    font-size: var(--fs-large) !important;
    justify-content: center;
}

.starting-price__icon {
    color: var(--c-primary);
    margin-top: 5px;
}

>>> .date-selection__clear-dates {
    & .fx-button-label {
        font-size: var(--fs-normal) !important;
        font-weight: bold !important;
    }
}

>>> .kind--link {
    color: var(--c-primary) !important;
    &:active {
        border-color: var(--c-primary-dk) !important;
        color: var(--c-primary-dk) !important;
    }
    &:hover, &:focus {
        border-color: var(--c-primary) !important;
    }
}

>>> .fx-event-list-error {
    background-color: white;
    border: 1px solid var(--c-gray-8);
}

>>> .flatpickr-day {
    &:hover {
        background-color: var(--c-primary-lt) !important;
        background: var(primary/) !important;
        border-color: var(--c-primary-lt) !important;
        &.disabled {
            background-color: none !important;
            background: none !important;
            border-color: transparent !important;
        }
        &.startRange, &.endRange {
            background-color: var(--c-primary) !important;
            background: var(--c-primary) !important;
            border-color: var(--c-primary) !important;
        }
        &.today {
            background-color: var(--c-primary-lt) !important;
            background: var(--c-primary-lt) !important;
        }
        &.nextMonthDay {
            background-color: var(--color-gray-lt) !important;
            background: var(--color-gray-lt) !important;
            border-color: var(--color-gray-lt) !important;
            &.inRange {
                background-color: var(--c-primary-lt) !important;
                border-color: var(--c-primary-lt) !important;
                box-shadow: -5px 0 0 var(--c-primary-lt), 5px 0 0 var(--c-primary-lt) !important;
            }
            &.startRange, &.endRange {
                background-color: var(--c-primary) !important;
                background: var(--c-primary) !important;
                border-color: var(--c-primary) !important;
                box-shadow: none !important;
            }
        }
    }
    &.inRange {
        background-color: var(--c-primary-lt) !important;
        border-color: var(--c-primary-lt) !important;
        box-shadow: -5px 0 0 var(--c-primary-lt), 5px 0 0 var(--c-primary-lt) !important;
    }
    &.startRange, &.endRange {
        background-color: var(--c-primary) !important;
        border-color: var(--c-primary) !important;
        box-shadow: none !important;
        &::after, &::before {
            background-color: var(--c-primary-lt) !important
        }
    }
}

>>> .fx-event-list-wrapper {
    border-bottom: none !important;
    border-radius: 0 !important;
}

>>> .loading-panel {
    border: 1px solid var(--c-gray-8) !important;
}

>>> .load-more-button {
    border-bottom: 1px solid var(--c-gray-8) !important;
}

>>> .date-row {
    color: var(--c-gray-5) !important;
}

>>> .fx-event-listing-date-component {
    & .date-row:last-child {
        font-size: var(--fs-normal);
    }
}

>>> .load-more-button {
    color: var(--c-primary) !important;
    &:hover {
        background-color: var(--c-primary-lt) !important;
    }
}

>>> .fx-event-date, >>> .fx-event-location {
    font-size: 13px !important;
    color: var(--c-gray-1) !important;
    line-height: 18px !important;
}

>>> .fx-event-title {
    color: var(--c-gray-5) !important;
    font-weight: var(--fw-medium) !important;
}

</style>
